<!-- 高清变焦相机Z60S -->
<template>
  <div class="cpt-MMC_Gimbal_ZT60R" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机HT40R</div>
      </div>
      <div class="close" @click="close">{{$t('operation.Close')}}</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制选项</div>
          <div class="input-box">
            <el-select
              v-model="control_mode"
              size="mini"
              placeholder="选择模式"
              @change="change_control_mode"
            >
              <el-option label="一键向下" :value="0" />
              <el-option label="航向回中" :value="1" />
              <el-option label="俯仰水平" :value="2" />
              <el-option label="回中" :value="3" />
            </el-select>
          </div>
        </div>

        <div class="form-item">
          <div class="label-box">点 击</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV1 == 0 }"
                  @click="handle_change_click_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV1 == 1 }"
                  @click="handle_change_click_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV1 == 2 }"
                  @click="handle_change_click_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">移动</div>
                <div class="text-right">聚焦</div>
                <div class="text-right">曝光</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">指点移动:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_zd_mode" v-model="zd_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">跟踪控制:</div>
          <div class="input-box">
            <el-radio-group v-model="dj_mode" @change="handle_change_dj_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="item-group jcsb ml100 w100">
          <div class="item-box" @click="handle_take_photo">
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            > -->
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt />
              </div>
              <span class='cf'> 拍照</span>
            <!-- </el-tooltip> -->
          </div>
          <div class="item-box" @click="handle_record">
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            > -->
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img
                  v-if="screenRecordingStatus"
                  src="~@/assets/images/mount/record.png"
                  alt
                />
                <img v-else src="~@/assets/images/mount/stop.png" alt />
              </div>
             <span class='cf'> 录像</span>
            <!-- </el-tooltip> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">AI识别:</div>
          <div class="input-box">
            <el-radio-group v-model="ai_mode" @change="handle_change_ai_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;倍
          </div>
          <div class="input-box">
            <el-input
            class="w140"
              v-model.number="zoom"
              size="mini"
              max="60"
              min="0"
              value="0"
              onblur="if(value>60)value=60;if(value<0)value=0;if(!value)value=0"
              placeholder="请输入变倍"
            />
            <el-button size="mini" type="primary" @click="handle_change_Zoom">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input
            class="w140"
              v-model.number="pitch"
              size="mini"
              max="30"
              min="-90"
              value="0"
              onblur="if(value>30)value=30;if(value<-90)value=-90;if(!value)value=0"
              placeholder="请输入俯仰"
            />
            <el-button size="mini" type="primary" @click="handle_change_pitch">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
            class="w140"
              v-model.number="yaw"
              max="145"
              min="-145"
              onblur="if(value>145)value=145;if(value<-145)value=-145;if(!value)value=0"
              size="mini"
              placeholder="请输入航向"
            />
            <el-button size="mini" type="primary" @click="handle_change_yaw">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10 cur"
              @mousedown="handle_zoom_reduce()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              @change="stopChange"
              @input="onChangezoom"
            />
            <div
              class="ml10 cur"
              @mousedown="handle_zoom_plus()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">画中画：</div>
          <div class="input-box">
            <el-select
              v-model="frameModel"
              size="mini"
              placeholder="选择模式"
              @change="change_frame_model"
            >
              <el-option label="高清" :value="0" />
              <el-option label="主高清" :value="1" />
              <el-option label="主红外" :value="2" />
              <el-option label="红外" :value="3" />
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">光圈调节</div>
          <div class="input-box">
            <el-select
              v-model="aqerturn_mode"
              size="mini"
              placeholder="选择模式"
              @change="change_aqerturn_mode"
            >
              <el-option label="关" :value="0" />
              <el-option label="1" :value="1" />
              <el-option label="2" :value="2" />
              <el-option label="3" :value="3" />
              <el-option label="4" :value="3" />
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">红外色板</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holdercolorDomV1 == 0 }"
                  @click="handle_change_click_color(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holdercolorDomV1 == 1 }"
                  @click="handle_change_click_color(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holdercolorDomV1 == 2 }"
                  @click="handle_change_click_color(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">白热</div>
                <div class="text-right">黑热</div>
                <div class="text-right">彩色</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">色彩增益</div>
          <div class="input-box">
            <el-select
              v-model="colour_mode"
              size="mini"
              placeholder="选择模式"
              @change="change_colour_mode"
            >
              <el-option label="关" :value="0" />
              <el-option label="1" :value="1" />
              <el-option label="2" :value="2" />
              <el-option label="3" :value="3" />
              <el-option label="4" :value="3" />
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box w87">可见度增强:</div>
          <div class="input-box">
            <el-radio-group
              v-model="visibility"
              @change="handle_change_visibility_mode"
            >
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子增稳</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_electron_value == 0 }"
                  @click="handle_change_electron_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_electron_value == 1 }"
                  @click="handle_change_electron_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_electron_value == 2 }"
                  @click="handle_change_electron_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">{{$t('operation.Close')}}</div>
                <div class="text-right">超级</div>
                <div class="text-right">超级+</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">夜间模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_night_value == 0 }"
                  @click="handle_change_night_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_night_value == 1 }"
                  @click="handle_change_night_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_night_value == 2 }"
                  @click="handle_change_night_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">自动</div>
                <div class="text-right">黑白</div>
                <div class="text-right">彩色</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">降 噪:</div>
          <div class="input-box">
            <el-radio-group v-model="jz_mode" @change="handle_change_jz_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">联控模式:</div>
          <div class="input-box">
            <el-radio-group v-model="lk_mode" @change="handle_change_lk_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">宽 动 态:</div>
          <div class="input-box">
            <el-radio-group v-model="Kdt_mode" @change="handle_change_kdt_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子变倍:</div>
          <div class="input-box">
            <el-radio-group v-model="dz_mode" @change="handle_change_dz_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">透雾:</div>
          <div class="input-box">
            <el-radio-group v-model="tw_mode" @change="handle_change_tw_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">白 平 衡</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_bph_value == 0 }"
                  @click="handle_change_bph_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_bph_value == 1 }"
                  @click="handle_change_bph_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_bph_value == 2 }"
                  @click="handle_change_bph_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">自动</div>
                <div class="text-right">室内</div>
                <div class="text-right">室外</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div ref="rocker" class="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="zuoUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="youUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
        </div>
      <div class="gcs_status_box" @dblclick="copyFn">  
        <div class="f12 mt13 ml10 gcs_status_item" style="color: #69e7f1">
          <span class="cf">跟踪状态：</span>
          {{ gcs_status || "未跟踪" }}
        </div>
        <div v-if="opacity.longitude">
          <div class="f12 mt3 ml10 gcs_status_item" style="color: #69e7f1">
            <span class="cf">目标经度：</span>
            {{ opacity.longitude || $t('home.no') }}
          </div>
          <div class="f12 mt3 ml10 gcs_status_item" style="color: #69e7f1">
            <span class="cf">目标维度：</span>
            {{ opacity.latitude || $t('home.no') }}
          </div>
          <div class="f12 mt3  ml10 gcs_status_item" style="color: #69e7f1">
            <span class="cf">激光测距：</span>
            {{ jgcj }}m
          </div>
        </div>

      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_Z60S } from "./js/index_1.js";
import { MMC_Gimbal_ZT60R } from "./js/index.js";
let targetPoint = null;
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      // 录像状态
      screenRecordingStatus: true,
      // nx状态
      keyFlag: true,
      // 画中画
      frameModel: 0,
      // 选择模式
      control_mode: 3,
      // 红外色板
      holdercolorDomV1: 1,
      // 点击
      holderModelDomV1: 1,
      // 云台模式
      holderModelDomV2: 1,
      // 跟踪控制
      dj_mode: 0,
      // 俯仰
      pitch: 0,
      // 航向
      yaw: 0,
      // 变倍
      zoom: 0,
      // 光圈调节
      aqerturn_mode: 0,
      // 色彩增益
      colour_mode: 0,
      // 可见度增强
      visibility: 1,
      // 电子增稳
      holder_electron_value: 1,
      // 夜间模式
      holder_night_value: 0,
      // 降噪
      jz_mode: 1,
      // 联控模式
      lk_mode: 0,
      zd_mode: 0,
      // 宽动态
      Kdt_mode: 0,
      // 电子变倍
      dz_mode: 0,
      // 透雾
      tw_mode: 0,
      // 白平衡
      holder_bph_value: 0,
      // AI识别
      ai_mode: 1,
      timer: null,
      value: 25,
      value2: 25,
      ptz_mode: 1,
      record: false,
      radio: 1,
      opacity: {},
      gcs_status: "",
      jgcj: 0,
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
      },
    };
  },
  computed: {
    stream() {
      return this.$store.state.fckernel.stream;
    },
    mountList() {
      return this.$store.state.uavApplications.payload;
    },
  },

  watch: {
    "mountList": {
      handler(val) {
       let mount= val['MMC_Gimbal_ZT60R']
      if(mount){
        this.resolve_payload(mount.payload);
      }
      },
      deep: true,
    },
    // payload
    "$store.state.uavApplications.healthData": {
      handler(newval) {
        let arrKey = [];
        for (let i in newval) {
          arrKey.push(i);
        }
        let flage = arrKey.some((val) => val == "NX");
        console.log(flage,newval,'flage');
        if (flage && newval["NX"].warningLevel == "NORMAL") {
          this.keyFlag = true;
        } else {
          this.keyFlag = false;
        }
      },
      deep: true,
    },
    // payload_data: {
    //   handler(value) {
    //     this.resolve_payload(value);
    //   },
    //   deep: true,
    // },
  },
  beforeDestroy() {
    if (targetPoint) {
      window.viewer.entities.remove(targetPoint);
      targetPoint = null;
    }
    this.$store.commit("device/SET_MOVE_DATA", {
      ...this.device,
      mountStatus: 0,
    });
  },
  methods: {
    copyFn() {
      // 获取所有gcs_status_item元素
      let gcsStatusItems = document.querySelectorAll(
        ".gcs_status_box .gcs_status_item"
      );
      // 检查元素是否存在
      if (gcsStatusItems.length > 0) {
        // 创建临时textarea元素
        let tempTextarea = document.createElement("textarea");
        let data = "";
        // 遍历所有gcs_status_item元素，拼接数据
        for (let i = 0; i < gcsStatusItems.length; i++) {
          let itemData =
            gcsStatusItems[i].textContent || gcsStatusItems[i].innerText;
          data += itemData + "\n";
        }
        tempTextarea.value = data;
        // 将临时textarea元素添加到body中
        document.body.appendChild(tempTextarea);
        // 选择临时textarea中的文本内容
        tempTextarea.select();
        // 复制文本内容到剪贴板
        document.execCommand("copy");
        // 移除临时textarea元素
        document.body.removeChild(tempTextarea);
        // 提示复制成功
       this.$message.success('复制成功')
      } else {
        console.log("gcs_status_item元素不存在");
      }
    },
    resolve_payload(buff) {
      const dataArray = new Uint8Array(buff);
      const opacity = MMC_Gimbal_ZT60R.gcs_transmit_ctrl(dataArray);
      if (opacity) {
        this.opacity = opacity;
      }
      const status = MMC_Gimbal_ZT60R.gcs_status(dataArray);
      if (status) this.gcs_status = status;
      if (opacity) {
        this.init(opacity);
      }
      const jgcj = MMC_Gimbal_ZT60R.get_msg_D1_parse(dataArray);
      if (jgcj != undefined) {
        this.jgcj = jgcj;
      }
    },
    init(opacity) {
      try {
        if (opacity && opacity.longitude) {
          const position = Cesium.Cartesian3.fromDegrees(
            opacity.longitude * 1,
            opacity.latitude * 1,
            opacity.altitude * 1
          );
          this.position = position;
          // 添加目标点
          if (!targetPoint && this.position) {
            targetPoint = window.viewer.entities.add({
              position: new Cesium.CallbackProperty(() => this.position, false),
              billboard: {
                image: require("./mb.svg"),
                width: 64,
                height: 64,
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 点击
    handle_change_click_mode(value) {
      this.holderModelDomV1 = value;
      const list = {
        0: [165, 79, 11, 85, 170, 220, 6, 30, 0, 1, 0, 25, 118],
        1: [165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 5, 8, 2, 255, 134, 186],
        2: [165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 89, 2, 255, 214, 84],
      };
      const buffer = list[value];
      this.commit_directive(buffer);
    },
    // 紅外色版
    handle_change_click_color(value) {
      this.holdercolorDomV1 = value;
      const list = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 3, 192, 218, 101],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 3, 128, 154, 209],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 4, 128, 157, 165],
      };
      const buffer = list[value];
      this.commit_directive(buffer);
    },
    // 画中画
    change_frame_model(value) {
      this.frameModel = value;
      const bufferList = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 0, 1, 24, 212],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 0, 3, 26, 111],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 0, 4, 29, 86],
        3: [165, 79, 10, 85, 170, 220, 5, 28, 0, 2, 27, 170],
      };
      const buffer = bufferList[value];
      this.commit_directive(buffer);
    },
    // 云台模式
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      const bufferList = {
        0: [
          165, 79, 17, 85, 170, 220, 12, 26, 10, 0, 0, 0, 0, 0, 0, 0, 0, 28,
          197,
        ],
        1: [
          165, 79, 17, 85, 170, 220, 12, 26, 3, 0, 0, 0, 0, 0, 0, 0, 0, 21, 53,
        ],
        2: [
          165, 79, 17, 85, 170, 220, 12, 26, 4, 0, 0, 0, 0, 0, 0, 0, 0, 18, 101,
        ],
      };
      const buffer = bufferList[value];
      this.commit_directive(buffer);
    },
    // 控制选项
    change_control_mode() {
      const { control_mode } = this;
      const control_modeList = {
        0: [
          165, 79, 17, 85, 170, 220, 12, 26, 18, 0, 0, 0, 0, 0, 0, 0, 0, 4, 105,
        ],
        1: [
          165, 79, 17, 85, 170, 220, 12, 26, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 180,
        ],
        2: [
          165, 79, 17, 85, 170, 220, 12, 26, 23, 0, 0, 0, 0, 0, 0, 0, 0, 1, 207,
        ],
        3: [
          165, 79, 17, 85, 170, 220, 12, 26, 4, 0, 0, 0, 0, 0, 0, 0, 0, 18, 101,
        ],
      };
      const buffer = control_modeList[control_mode];
      this.commit_directive(buffer);
    },
    handle_change_zd_mode(id) {
      this.$store.commit("uavApplications/setState", {
        key: "move_data",
        value: { mountStatus: id, name: "MMC_Gimbal_ZT60R" },
      });
    },
    // 跟踪控制
    handle_change_dj_mode(id) {
      let buffer = null;
      // 停止
      if (id == 0) {
        const list = {
          0: [165, 79, 11, 85, 170, 220, 6, 30, 0, 1, 0, 25, 118],
          1: [
            165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 5, 8, 2, 255, 134, 186,
          ],
          2: [
            165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 89, 2, 255, 214, 84,
          ],
        };
        const buffer = list[this.holderModelDomV1];
        this.commit_directive(buffer);
        this.$store.commit("uavApplications/setState", {
          key: "move_data",
          value: { mountStatus: 0, name: "MMC_Gimbal_ZT60R" },
        });
      } else {
        // 控制
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 3, 255, 186, 205,
        ];
        this.$store.commit("uavApplications/setState", {
          key: "move_data",
          value: { mountStatus: id, name: "MMC_Gimbal_ZT60R" },
        });
      }
      this.commit_directive(buffer);
    },
    handle_change_pitch() {
      if (this.pitch > 200) {
        this.pitch = 200;
      }
      if (this.pitch) {
        const buffer = MMC_Gimbal_Z60S.change_pitch_angle(this.pitch);
        this.commit_directive(buffer);
      }
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_Z60S.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    // 变倍
    handle_change_Zoom() {
      const buffer = MMC_Gimbal_ZT60R.zoomTo(this.zoom);
      this.commit_directive(buffer);
    },
    // 变焦 -
    handle_zoom_reduce() {
      const list = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 2, 57, 34, 195],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 2, 59, 32, 120],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 2, 60, 39, 65],
        3: [165, 79, 10, 85, 170, 220, 5, 28, 2, 58, 33, 189],
      };
      this.commit_directive(list[this.frameModel]);
    },
    // 变焦 +
    handle_zoom_plus() {
      const list = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 2, 121, 98, 119],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 2, 123, 96, 204],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 2, 124, 103, 245],
        3: [165, 79, 10, 85, 170, 220, 5, 28, 2, 122, 97, 9],
      };
      this.commit_directive(list[this.frameModel]);
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      const list = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 0, 121, 96, 153],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 0, 123, 98, 34],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 0, 124, 101, 27],
        3: [165, 79, 10, 85, 170, 220, 5, 28, 0, 122, 99, 231],
      };
      this.commit_directive(list[this.frameModel]);
    },
    handle_zoom(mode) {
      const list = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 0, 121, 96, 153],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 0, 123, 98, 34],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 0, 124, 101, 27],
        3: [165, 79, 10, 85, 170, 220, 5, 28, 0, 122, 99, 231],
      };
      this.commit_directive(list[this.frameModel]);
    },
    // 光圈调节
    change_aqerturn_mode() {
      const { aqerturn_mode } = this;
      if (aqerturn_mode != 0) {
        const buffer = [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 2, 255, 246,
          82,
        ];
        this.commit_directive(buffer);
      }
      const aqerturn_mode_list = {
        0: [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 0, 255, 244,
          188,
        ],
        1: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 2, 0,
          255, 214, 106,
        ],
        2: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 4, 0,
          255, 208, 197,
        ],
        3: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 6, 0,
          255, 210, 160,
        ],
        4: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 8, 0,
          255, 220, 170,
        ],
      };
      this.commit_directive(aqerturn_mode_list[aqerturn_mode]);
    },
    // 色彩增益
    change_colour_mode() {
      const { colour_mode } = this;
      if (colour_mode != 0) {
        const buffer = [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 6, 255, 242,
          191,
        ];
        this.commit_directive(buffer);
      }
      const colour_mode_list = {
        0: [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 2, 255, 246,
          82,
        ],
        1: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 2, 0,
          255, 221, 85,
        ],
        2: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 4, 0,
          255, 219, 250,
        ],
        3: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 6, 0,
          255, 217, 159,
        ],
        4: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 8, 0,
          255, 215, 149,
        ],
      };
      this.commit_directive(colour_mode_list[colour_mode]);
    },
    // 可见度增强
    handle_change_visibility_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 3, 255, 179, 194,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 6, 255, 182, 88,
        ];
      }
      this.commit_directive(buffer);
    },
    // 电子增稳
    handle_change_electron_mode(num) {
      this.holder_electron_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 3, 255, 186, 205,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 2, 255, 187, 186,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 2, 255, 187, 186,
        ];
      }
      this.commit_directive(buffer);
    },
    // 夜间模式
    handle_change_night_mode(num) {
      this.holder_night_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 81, 3, 255, 223, 134,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 1, 2, 255, 142, 87,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 1, 3, 255, 143, 32,
        ];
      }
      this.commit_directive(buffer);
    },
    // 降噪
    handle_change_jz_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 83, 0, 255, 222, 122,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 83, 5, 255, 219, 224,
        ];
      }
      this.commit_directive(buffer);
    },
    handle_change_lk_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [166, 1, 3, 0, 107];
      } else {
        buffer = [166, 1, 3, 1, 90];
      }
      this.commit_directive(buffer);
    },
    // 宽动态
    handle_change_kdt_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 3, 255, 179, 194,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 2, 255, 178, 181,
        ];
      }
      this.commit_directive(buffer);
    },
    // 电子变倍
    handle_change_dz_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 6, 3, 255, 136, 37,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 6, 2, 255, 137, 82,
        ];
      }
      this.commit_directive(buffer);
    },
    // 透雾
    handle_change_tw_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 15, 85, 170, 220, 10, 255, 129, 1, 4, 55, 3, 0, 255, 186,
          187,
        ];
      } else {
        buffer = [
          165, 79, 15, 85, 170, 220, 10, 255, 129, 1, 4, 55, 2, 3, 255, 184,
          136,
        ];
      }
      this.commit_directive(buffer);
    },
    // 白平衡
    handle_change_bph_mode(num) {
      this.holder_bph_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 53, 0, 255, 184, 254,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 53, 1, 255, 185, 137,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 53, 2, 255, 186, 16,
        ];
      }
      this.commit_directive(buffer);
    },
    // AI识别
    handle_change_ai_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [165, 79, 11, 85, 170, 220, 6, 30, 0, 5, 0, 29, 155];
      } else {
        buffer = [165, 79, 11, 85, 170, 220, 6, 30, 0, 5, 1, 28, 94];
      }
      this.commit_directive(buffer);
    },
    close() {
      if (this.record) {
        this.$message.info("请结束录像后再关闭！");
      } else {
        this.$emit("close");
      }
    },
    handle_take_photo() {
      // 拍照
      this.commit_directive([
        165, 79, 10, 85, 170, 220, 5, 28, 4, 192, 221, 17,
      ]);

      // nx拍照
      if (!this.keyFlag) return this.$message.error("拍照失败，NX通信异常！");
      this.$store.dispatch("uavApplications/TakePhoto", {
        callback: (isOk) => {
          isOk && this.$message.success('拍照成功')
        },
      });
    },
    handle_record() {
      this.commit_directive([165, 79, 10, 85, 170, 220, 5, 28, 5, 0, 28, 139]);
      if (!this.keyFlag) return this.$message.error("录像失败，NX通信异常！");
      this.screenRecording();
    },
    screenRecording() {
      this.$store.dispatch("uavApplications/videoTranscribe", {
        videoID: 1, // 视频通道ID（需保持唯一）
        dbID: "929", // 历史记录id
        taskID: "", // 任务ID，可传可不传
        recordControl: true, // 录制开关、true为开启
        status: this.screenRecordingStatus,
        callback: (isOk) => {
          isOk &&
            this.$message.success(
              `${this.screenRecordingStatus ? "开始" : "结束"}录屏`
            );
          this.screenRecordingStatus = !this.screenRecordingStatus;
        },
      });
    },
    onChangezoom(e) {
      console.log(e, "e");
      if (e < 25) {
        const arr = [165, 4, 3, 0, 4];
        this.commit_directive(arr);
        // this.handle_zoom(0);
      }
      if (e > 25) {
        const arr = [165, 4, 3, 2, 102];
        this.commit_directive(arr);
      }
      if (e == 25) {
        this.handle_zoom(1);
      }
      this.value2 = e;
    },

    commit_directive(buffer) {
      // console.log(buffer, "buffer");
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 4, 176,
            118, 108,
          ];
          this.commit_directive(buffer);

          break;
        case "left":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 7, 8, 0, 0, 5, 220,
            205, 15,
          ];
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 4, 176, 0, 0, 5, 220,
            118, 216,
          ];
          this.commit_directive(buffer);

          break;
        case "down":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 7, 8,
            205, 85,
          ];
          this.commit_directive(buffer);
      }
    },
    stopfxFn() {
      const buffer = [
        165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 5, 220, 27,
        60,
      ];
      this.commit_directive(buffer);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_ZT60R {
  width: 470px;
  height: 290px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  box-sizing: border-box;
  // padding: 10px 20px;
  position: absolute;
  right: 0px;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    height: 230px;

    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      position: absolute;
      right: 30px;
      // flex: 1;

      .title-box {
        .title {
          color: #cbd0eb;
        }
      }

      .rocker {
        // margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep.el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}

// 变焦功能
.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}

.cur {
  cursor: pointer;
}
</style>
